import { AdvancedVideo } from "@cloudinary/react"
import { VideoEdit } from "@cloudinary/url-gen/actions"
import { domAnimation, LazyMotion, m } from "framer-motion"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import React, { useEffect, useMemo, useState } from "react"
import { fetchHomePageTextQuery } from "../../graphql/queries/fetchHomePageText"
import { DisplayType, useDisplayType } from "../../helpers/useWindowDimesnsions"
import scroll from "../../images/homePage/scroll.svg"
import { cloudinary } from "../../repositories/cloudinary"
import Button from "../Button"
import * as styles from "./HomePage.module.scss"

const textVariants = {
  hidden: {
    opacity: 0,
  },
  animation: {
    opacity: 1,
    transition: {
      opacity: {
        duration: 1.5,
        easing: "ease",
      },
    },
  },
}

const SCROLL_ACTIVATION_DISTANCE = 0

export const HomePage: React.FC = () => {
  const { titleFirstLine, titleSecondLinePlainText, titleSecondLineColoredText, fullDetails, shortDetails } = fetchHomePageTextQuery()
  const breakpoints = useBreakpoint()
  const [showScrollIcon, setShowScrollIcon] = useState(true)
  const displayType = useDisplayType()

  const getBackgroundVideo = () => {
    var backgroundVideo

    if (displayType === DisplayType.Desktop) {
      backgroundVideo = cloudinary.video("v1734102598/video_desktop_move_sign")
    } else if (displayType === DisplayType.Tablet) {
      backgroundVideo = cloudinary.video("v1734103754/video_tablet")
    } else {
      backgroundVideo = cloudinary.video("v1734102985/video_mobile")
    }

    return backgroundVideo.quality("auto")
  }

  // NOTE: Extension of the video is .jpg, but it is actually a video
  // API of cloudinary
  const getBackgroundVideoThumbnail = () => {
    if (displayType === DisplayType.Desktop) {
      return cloudinary
        .image("v1734102598/video_desktop_move_sign.jpg")
        .addAction(VideoEdit.trim().startOffset(0.0))
        .setAssetType("video")
    } else if (displayType === DisplayType.Tablet) {
      return cloudinary
        .image("v1734103754/video_tablet.jpg")
        .addAction(VideoEdit.trim().startOffset(0.0))
        .setAssetType("video")
    } else {
      return cloudinary
        .image("v1734102985/video_mobile.jpg")
        .addAction(VideoEdit.trim().startOffset(0.0))
        .setAssetType("video")
    }
  }

  const backgroundVideoComponent = useMemo(
    () => (
      <AdvancedVideo
        cldVid={getBackgroundVideo()}
        poster={getBackgroundVideoThumbnail().toURL()}
        autoPlay
        muted
        loop
        playsInline
      />
    ),
    [displayType]
  )

  const handelScroll = () =>
    setShowScrollIcon(window.scrollY <= SCROLL_ACTIVATION_DISTANCE)

  useEffect(() => {
    window.addEventListener("scroll", handelScroll)
    return () => window.removeEventListener("scroll", handelScroll)
  }, [])

  return (
    <LazyMotion features={domAnimation}>
      <div className={styles.wrapper} id="main">
        <div className={styles.backgroundVideo}>{backgroundVideoComponent}</div>
        <div className={styles.container}>
          <m.div
            className={styles.page}
            variants={textVariants}
            animate={"animation"}
            initial={"hidden"}
          >
            <div className={styles.information}>
              <h1 className={styles.informationTitle}>
                <span className={styles.informationTitleFirstLine}>
                  {titleFirstLine}
                </span>
                <br className={styles.informationTitleBreak} />
                {titleSecondLinePlainText}
                <span className={styles.informationTitleColored}>
                  {titleSecondLineColoredText}
                </span>
              </h1>
              <div
                className={styles.informationDescription}
                dangerouslySetInnerHTML={{
                  __html: breakpoints.xl ? shortDetails.childMarkdownRemark.html : fullDetails.childMarkdownRemark.html,
                }}
              />
              <div className={styles.informationContact}>
                <Button
                  text={"Contact Us"}
                  color={"violet"}
                  className={styles.informationContactButton}
                  onClick={() =>
                    (window.location.href = "mailto:info@v4scale.com")
                  }
                />
              </div>
            </div>
          </m.div>
          {Object.keys(breakpoints).length > 0 && !breakpoints.xl && (
            <div
              className={
                showScrollIcon ? styles.scrollIcon : styles.hiddenScrollIcon
              }
            >
              <img src={scroll} alt="Scroll" />
            </div>
          )}
        </div>
      </div>
    </LazyMotion>
  )
}
export default HomePage


